<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction } from '@/command/netTool'
import { suitNum } from '@/utils/textFile'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import moment from 'moment'
import { message } from 'ant-design-vue'
import { nextTick } from 'vue'

export default {
  name: 'farmingDetail',
  data() {
    return {
      ...api.command.getState(),
      labelGroup: [],
      // 0 采摘 1 垂钓
      type: '0',
      detail: {
        fromType: null,
        productId: null,
        startDate: null,
        endDate: null,
        eventId: null,
        quantity: null,
        concatName: null,
        concatIdCard: null,
        concatPhone: null,
        buyDate: null,
      },
      isShowSnapshot: false,
      storeData: [],
      canUseDate: [],
      calendarList: [],
      currentProduct: {},
      eventIds: [],
      quantityDisabled: false,
    }
  },
  mounted() {
    const { id } = this.$route.query

    if (id) {
      getAction('/api/whiteListOrder/detail', { id }).then((res) => {
        const data = res.data
        this.detail = {
          ...data,
          quantity: data.productQuantity,
          ...data.visitorList[0],
          startDate: moment(data.effectiveStartTime).format('YYYY-MM-DD'),
          endDate: moment(data.effectiveEndTime).format('YYYY-MM-DD'),
        }
      })
    }
  },
  computed: {
    disabled() {
      return !!this.$route.query.id
    },
  },
  methods: {
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/whiteListOrder/orderProductPage?fromType=${this.detail.fromType}&productName=${value}`,
      })

      this.storeData = searchResult.records.map((el) => {
        return {
          ...el,
          name: el.productName,
          value: el.productId,
        }
      })
    },
    onMenuChange(data) {
      this.currentProduct = data
      this.detail.startDate = null
      this.detail.endDate = null
      this.detail.eventId = null
      this.detail.buyDate = null
      this.eventIds = []
      if (!this.quantityDisabled) {
        this.detail.quantity = null
      }
      getAction('/api/whiteListOrder/getCalendarList', {
        productId: data.value,
        fromType: this.detail.fromType,
        skuId: data.skuId,
      }).then((res) => {
        this.canUseDate = (res.data || []).map((d) => moment(d.date).valueOf() + '')
        this.calendarList = res.data
        this.$forceUpdate()
      })
    },
    onDateSelect(value) {
      const obj = this.calendarList.find((d) => d.date == moment(value).format('YYYY-MM-DD'))
      this.eventIds = obj.ticketRoundList.map((d) => {
        return { value: d.thirdRoundCode, label: d.name, name: d.name }
      })
    },
    fromTypeChange(value) {
      this.detail.productId = null
      this.detail.startDate = null
      this.detail.endDate = null
      this.detail.buyDate = null
      this.detail.eventId = null
      this.detail.quantity = null
      this.storeData = []
      this.canUseDate = []
      this.calendarList = []
      this.currentProduct = {}
      this.eventIds = []
      if (value == 14) {
        this.detail.quantity = 1
        this.quantityDisabled = true
      } else {
        this.quantityDisabled = false
      }
    },
    getForm1() {
      return {
        title: '商品信息',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '商品类型',
            type: 'select',
            cols: 12,
            disabled: this.disabled,
            key: 'fromType',
            onChange: this.fromTypeChange,
            typeData: [
              //   {
              //     name: '景点门票',
              //     value: '12',
              //   },
              //   {
              //     name: '酒店民宿',
              //     value: '13',
              //   },
              {
                name: '组合套餐',
                value: '14',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '选择商品',
            type: 'dropDownInput',
            cols: 12,
            disabled: this.disabled,
            key: 'productName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.onMenuChange,
            valueKey: 'productId',
            inputProps: {
              placeholder: '搜索商品',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display:
              !this.disabled &&
              (this.detail.fromType == 12 || (this.detail.fromType == 14 && this.currentProduct.comboType == 1)),
            type: 'row',
            cols: 12,
            children: [
              {
                name: '使用日期',
                type: 'datePicker',
                key: 'buyDate',
                formatdate: (value) => {
                  return value && moment(value).format('YYYY-MM-DD')
                },
                cols: 24,
                disabled: this.disabled,
                canUseDate: this.canUseDate,
                onDateSelect: this.onDateSelect,
                rules: [
                  {
                    required: true,
                    type: 'date',
                  },
                ],
              },
            ],
          },
          {
            display:
              this.disabled ||
              !(this.detail.fromType == 12 || (this.detail.fromType == 14 && this.currentProduct.comboType == 1)),
            type: 'row',
            cols: 12,

            children: [
              {
                name: this.disabled ? '有效期' : '使用日期',
                type: 'rangePicker',
                keys: ['startDate', 'endDate'],
                cols: 24,
                disabled: this.disabled,
                canUseDate: this.canUseDate,
                rules: [
                  {
                    required: true,
                  },
                ],
              },
            ],
          },
          {
            display: this.detail.fromType == 12 && this.eventIds.length > 0,
            name: '场次',
            type: 'select',
            cols: 12,
            key: 'eventId',
            typeData: this.eventIds,
            disabled: this.disabled,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '赠送数量',
            type: 'inputNumber',
            cols: 12,
            key: 'quantity',
            disabled: this.disabled || this.quantityDisabled,
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
            props: {
              min: 0,
            },
          },
        ].filter((d) => d.display),
      }
    },
    getForm2() {
      return {
        title: '用户信息',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '名称',
            type: 'input',
            cols: 12,
            props: {
              disabled: this.disabled,
            },
            key: 'concatName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display:
              this.currentProduct.realNameInput && this.currentProduct.realNameInput.indexOf('link_credit_no') > -1,
            name: '身份证',
            type: 'input',
            cols: 12,
            props: {
              disabled: this.disabled,
            },
            key: 'concatIdCard',
            rules: [
              {
                ruleType: 'idCard',
                required: true,
                type: 'string',
                message: '请输入正确的身份证号',
              },
            ],
          },
          {
            display: true,
            name: '手机号',
            type: 'input',
            cols: 12,
            props: {
              disabled: this.disabled,
            },
            key: 'concatPhone',
            rules: [
              {
                ruleType: 'phone',
                required: true,
                type: 'string',
                message: '请输入正确的手机号',
              },
            ],
          },
        ].filter((d) => d.display),
      }
    },

    getString(str) {
      // eslint-disable-next-line prettier/prettier
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            let parmas = {
              ...this.currentProduct,
              ...data,
              isEvent: this.detail.fromType == 12 && this.eventIds.length > 0 ? '1' : '0',
              ticketProductList: [
                {
                  concatIdCard: data.concatIdCard,
                  concatName: data.concatName,
                  concatPhone: data.concatPhone,
                },
              ],
            }
            postAction('/whiteListOrder/createOrder', parmas).then((res) => {
              this.$message.success('添加成功')
              history.back()
            })
            return
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      const right = [
        {
          name: `返回`,
          type: 'default',
          onClick: () => {
            history.back()
          },
        },
      ]
      return {
        left: !this.disabled && left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          productName: this.storeData,
        }}
        data={[this.getForm1(), this.getForm2()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
